import {useEffect, useState} from "react";
import TypeShow2 from "./TypeShow";

export default function Pokemon({ mon, owned }) {
//name, pts, type1, type2, atk, def, spdf, spa, spe, hp, ability1, ability2, ability3
    const [hasRocks, setHasRocks] = useState("");
    const [hasSpin, setHasSpin] = useState("");
    const [hasFog, setHasFog] = useState("");
    const [hasWish, setHasWish] = useState("");
    const [hasCureStatus, setHasCureStatus] = useState("");
    const [hasScreens, setHasScreens] = useState("");
    const [hasWebs, setHasWebs] = useState("");
    const [hasTidyUp, setHasTidyUp] = useState("");
    const [hasSpikes, setHasSpikes] = useState("");
    const [hasSucker, setHasSucker] = useState("");
    const [hasExtremeSpeed, setHasExtremeSpeed] = useState("");
    const [hasBounce, setHasBounce] = useState("");

    //tidy up, sucker punch, extreme speed, spikes
    const name = mon.name;
    const type1 = mon.type1;
    const type2 = mon.type2;
    const atk = mon.atk;
    const def = mon.def;
    const spa = mon.spa;
    const spdf = mon.spd;
    const hp = mon.hp;
    const spe = mon.spe;
    const src = "https://play.pokemonshowdown.com/sprites/gen5/" + mon.smogonName + ".png";
    const smogonName = mon.smogonName;
    // this is where we add the little status effects / rocks / etc to the mon card.
    useEffect(() => {

const webmons = [
    'spinarak',
    'ariados',
    'shuckle',
    'surskit',
    'masquerain',
    'kricketune',
    'sewaddle',
    'swadloon',
    'galvantula',
    'swirlix',
    'slurpuff',
    'grubbin',
    'charjabug',
    'vikavolt',
    'cutiefly',
    'ribombee',
    'dewpider',
    'araquanid',
    'blipbug',
    'tarountula',
    'spidops',
];
        const screenmons = [

            'bulbasaur',
            'ivysaur',
            'venusaur',
            'pikachu',
            'raichu',
            'clefairy',
            'clefable',
            'jigglypuff',
            'wigglytuff',
            'paras',
            'parasect',
            'psyduck',
            'golduck',
            'abra',
            'kadabra',
            'alakazam',
            'machop',
            'machoke',
            'machamp',
            'slowpoke',
            'slowbro',
            'magnemite',
            'magneton',
            'cloyster',
            'drowzee',
            'hypno',
            'voltorb',
            'electrode',
            'exeggcute',
            'exeggutor',
            'chansey',
            'staryu',
            'starmie',
            'mr-mime',
            'scyther',
            'jynx',
            'electabuzz',
            'jolteon',
            'articuno',
            'zapdos',
            'dratini',
            'dragonair',
            'dragonite',
            'mewtwo',
            'mew',
            'chikorita',
            'bayleef',
            'meganium',
            'ledyba',
            'ledian',
            'pichu',
            'cleffa',
            'igglybuff',
            'togepi',
            'togetic',
            'natu',
            'xatu',
            'mareep',
            'flaaffy',
            'ampharos',
            'marill',
            'azumarill',
            'hoppip',
            'skiploom',
            'jumpluff',
            'sunkern',
            'sunflora',
            'espeon',
            'umbreon',
            'slowking',
            'girafarig',
            'pineco',
            'forretress',
            'scizor',
            'slugma',
            'magcargo',
            'swinub',
            'piloswine',
            'corsola',
            'stantler',
            'smoochum',
            'elekid',
            'blissey',
            'raikou',
            'lugia',
            'ho-oh',
            'celebi',
            'dustox',
            'ralts',
            'kirlia',
            'gardevoir',
            'azurill',
            'sableye',
            'meditite',
            'medicham',
            'electrike',
            'manectric',
            'plusle',
            'minun',
            'volbeat',
            'illumise',
            'spoink',
            'grumpig',
            'lunatone',
            'solrock',
            'baltoy',
            'claydol',
            'feebas',
            'milotic',
            'chimecho',
            'snorunt',
            'glalie',
            'metang',
            'metagross',
            'latias',
            'latios',
            'jirachi',
            'deoxys-normal',
            'turtwig',
            'grotle',
            'torterra',
            'shinx',
            'luxio',
            'luxray',
            'pachirisu',
            'chingling',
            'bronzor',
            'bronzong',
            'mime-jr',
            'happiny',
            'snover',
            'abomasnow',
            'magnezone',
            'electivire',
            'togekiss',
            'mamoswine',
            'gallade',
            'froslass',
            'rotom',
            'uxie',
            'mesprit',
            'azelf',
            'cresselia',
            'manaphy',
            'arceus',
            'victini',
            'snivy',
            'servine',
            'serperior',
            'watchog',
            'munna',
            'musharna',
            'blitzle',
            'zebstrika',
            'woobat',
            'swoobat',
            'audino',
            'sewaddle',
            'swadloon',
            'leavanny',
            'whimsicott',
            'lilligant',
            'sigilyph',
            'cinccino',
            'gothita',
            'gothorita',
            'gothitelle',
            'solosis',
            'duosion',
            'reuniclus',
            'vanillite',
            'vanillish',
            'vanilluxe',
            'deerling',
            'sawsbuck',
            'emolga',
            'alomomola',
            'joltik',
            'galvantula',
            'eelektrik',
            'eelektross',
            'elgyem',
            'beheeyem',
            'cryogonal',
            'larvesta',
            'volcarona',
            'virizion',
            'reshiram',
            'zekrom',
            'kyurem',
            'meloetta-aria',
            'genesect',
            'fennekin',
            'braixen',
            'delphox',
            'vivillon',
            'flabebe',
            'floette',
            'florges',
            'espurr',
            'meowstic-male',
            'spritzee',
            'aromatisse',
            'swirlix',
            'slurpuff',
            'inkay',
            'malamar',
            'helioptile',
            'heliolisk',
            'amaura',
            'aurorus',
            'sylveon',
            'dedenne',
            'carbink',
            'klefki',
            'pumpkaboo-average',
            'gourgeist-average',
            'xerneas',
            'diancie',
            'hoopa',
            'rowlet',
            'dartrix',
            'decidueye',
            'primarina',
            'grubbin',
            'charjabug',
            'vikavolt',
            'cutiefly',
            'ribombee',
            'toxapex',
            'morelull',
            'shiinotic',
            'bounsweet',
            'steenee',
            'tsareena',
            'comfey',
            'oranguru',
            'pyukumuku',
            'minior-red-meteor',
            'mimikyu-disguised',
            'bruxish',
            'drampa',
            'tapu-koko',
            'tapu-lele',
            'tapu-bulu',
            'tapu-fini',
            'solgaleo',
            'lunala',
            'nihilego',
            'xurkitree',
            'necrozma',
            'magearna',
            'stakataka',
            'blacephalon',
            'sobble',
            'drizzile',
            'inteleon',
            'corviknight',
            'dottler',
            'orbeetle',
            'gossifleur',
            'eldegoss',
            'appletun',
            'polteageist',
            'hatenna',
            'hattrem',
            'hatterene',
            'impidimp',
            'morgrem',
            'grimmsnarl',
            'cursola',
            'mr-rime',
            'alcremie',
            'frosmoth',
            'duraludon',
            'drakloak',
            'dragapult',
            'zamazenta',
            'eternatus',
            'regieleki',
            'regidrago',
            'calyrex',
            'wyrdeer',
            'kleavor',
            'arboliva',
            'armarouge',
            'ceruledge',
            'tadbulb',
            'bellibolt',
            'toedscool',
            'toedscruel',
            'rabsca',
            'flittle',
            'espathra',
            'tinkatink',
            'tinkatuff',
            'tinkaton',
            'glimmet',
            'glimmora',
            'farigiraf',
            'scream-tail',
            'sandy-shocks',
            'iron-moth',
            'gimmighoul',
            'gholdengo',
            'wo-chien',
            'chi-yu',
            'iron-valiant',
            'miraidon',
            'munkidori',
            'fezandipiti',
            'archaludon',
            'deoxys-attack',
            'deoxys-defense',
            'deoxys-speed',
            'rotom-heat',
            'rotom-wash',
            'rotom-frost',
            'rotom-fan',
            'rotom-mow',
            'meloetta-pirouette',
            'kyurem-black',
            'kyurem-white',
            'meowstic-female',
            'pumpkaboo-small',
            'pumpkaboo-large',
            'pumpkaboo-super',
            'gourgeist-small',
            'gourgeist-large',
            'gourgeist-super',
            'venusaur-mega',
            'alakazam-mega',
            'mewtwo-mega-x',
            'mewtwo-mega-y',
            'ampharos-mega',
            'scizor-mega',
            'gardevoir-mega',
            'medicham-mega',
            'manectric-mega',
            'abomasnow-mega',
            'latias-mega',
            'latios-mega',
            'gallade-mega',
            'audino-mega',
            'slowbro-mega',
            'glalie-mega',
            'diancie-mega',
            'metagross-mega',
            'pikachu-rock-star',
            'pikachu-belle',
            'pikachu-pop-star',
            'pikachu-phd',
            'pikachu-libre',
            'pikachu-cosplay',
            'hoopa-unbound',
            'pikachu-original-cap',
            'pikachu-hoenn-cap',
            'pikachu-sinnoh-cap',
            'pikachu-unova-cap',
            'pikachu-kalos-cap',
            'pikachu-alola-cap',
            'raichu-alola',
            'exeggutor-alola',
            'vikavolt-totem',
            'minior-orange-meteor',
            'minior-yellow-meteor',
            'minior-green-meteor',
            'minior-blue-meteor',
            'minior-indigo-meteor',
            'minior-violet-meteor',
            'minior-red',
            'minior-orange',
            'minior-yellow',
            'minior-green',
            'minior-blue',
            'minior-indigo',
            'minior-violet',
            'mimikyu-busted',
            'mimikyu-totem-disguised',
            'mimikyu-totem-busted',
            'magearna-original',
            'pikachu-partner-cap',
            'ribombee-totem',
            'necrozma-dusk',
            'necrozma-dawn',
            'necrozma-ultra',
            'pikachu-starter',
            'pikachu-world-cap',
            'slowpoke-galar',
            'slowbro-galar',
            'mr-mime-galar',
            'articuno-galar',
            'zapdos-galar',
            'slowking-galar',
            'corsola-galar',
            'indeedee-female',
            'zamazenta-crowned',
            'eternatus-eternamax',
            'calyrex-ice',
            'calyrex-shadow',
            'gimmighoul-roaming',
            'miraidon-low-power-mode',
            'miraidon-drive-mode',
            'miraidon-aquatic-mode',
            'miraidon-glide-mode',

        ];
        const curemons = [
            'vileplume',
            'paras',
            'parasect',
            'chansey',
            'chikorita',
            'bayleef',
            'meganium',
            'cleffa',
            'hoppip',
            'illumise',
            'roselia',
            'roserade',
            'vespiquen',
            'cherubi',
            'happiny',
            'shaymin-land',
            'petilil',
            'lilligant',
            'deerling',
            'sawsbuck',
            'vivillon',
            'flabebe',
            'floette',
            'florges',
            'spritzee',
            'aromatisse',
            'swirlix',
            'slurpuff',
            'xerneas',
            'cutiefly',
            'ribombee',
            'fomantis',
            'bounsweet',
            'steenee',
            'tsareena',
            'comfey',
            'tapu-lele',
            'gossifleur',
            'eldegoss',
            'sinistea',
            'polteageist',
            'hatenna',
            'hattrem',
            'hatterene',
            'milcery',
            'alcremie',
            'indeedee-male',
            'calyrex',
            'floette-eternal',
            'ribombee-totem',
            'weezing-galar',
            'indeedee-female',
            'calyrex-ice',
            'calyrex-shadow',
            'clefairy',
            'clefable',
            'jigglypuff',
            'wigglytuff',
            'chansey',
            'jynx',
            'lapras',
            'eevee',
            'vaporeon',
            'jolteon',
            'flareon',
            'mew',
            'chinchou',
            'lanturn',
            'igglybuff',
            'togepi',
            'togetic',
            'mareep',
            'flaaffy',
            'ampharos',
            'espeon',
            'umbreon',
            'misdreavus',
            'snubbull',
            'granbull',
            'smoochum',
            'miltank',
            'blissey',
            'celebi',
            'gardevoir',
            'skitty',
            'delcatty',
            'spoink',
            'grumpig',
            'swablu',
            'altaria',
            'chimecho',
            'kricketune',
            'buneary',
            'lopunny',
            'mismagius',
            'chingling',
            'happiny',
            'togekiss',
            'leafeon',
            'glaceon',
            'uxie',
            'phione',
            'manaphy',
            'munna',
            'musharna',
            'audino',
            'leavanny',
            'petilil',
            'lilligant',
            'gothita',
            'gothorita',
            'gothitelle',
            'meloetta-aria',
            'flabebe',
            'floette',
            'florges',
            'espurr',
            'meowstic-male',
            'spritzee',
            'aromatisse',
            'swirlix',
            'slurpuff',
            'sylveon',
            'diancie',
            'comfey',
            'magearna',
            'meloetta-pirouette',
            'meowstic-female',
            'ampharos-mega',
            'gardevoir-mega',
            'floette-eternal',
            'altaria-mega',
            'audino-mega',
            'diancie-mega',
            'lopunny-mega',
            'vulpix-alola',
            'ninetales-alola',
            'magearna-original',

        ];
        const wishmons = ['clefairy',
            'clefable',
            'eevee',
            'pichu',
            'cleffa',
            'igglybuff',
            'togepi',
            'togetic',
            'natu',
            'xatu',
            'girafarig',
            'smoochum',
            'gardevoir',
            'skitty',
            'plusle',
            'minun',
            'illumise',
            'spinda',
            'chimecho',
            'luvdisc',
            'latias',
            'jirachi',
            'chingling',
            'togekiss',
            'pidove',
            'audino',
            'alomomola',
            'fennekin',
            'braixen',
            'delphox',
            'flabebe',
            'floette',
            'florges',
            'spritzee',
            'swirlix',
            'slurpuff',
            'komala',
            'togedemaru',
            'pawmi',
            'fidough',
            'scream-tail',
            'flutter-mane',
            'gardevoir-mega',
            'floette-eternal',
            'latias-mega',
            'audino-mega',
            'togedemaru-totem',
        ];
        const spinmons = [
            'squirtle',
            'wartortle',
            'blastoise',
            'sandshrew',
            'sandslash',
            'tentacool',
            'tentacruel',
            'shellder',
            'hitmonlee',
            'hitmonchan',
            'staryu',
            'starmie',
            'kabuto',
            'pineco',
            'forretress',
            'delibird',
            'donphan',
            'tyrogue',
            'hitmontop',
            'torkoal',
            'spinda',
            'baltoy',
            'claydol',
            'anorith',
            'drilbur',
            'excadrill',
            'cryogonal',
            'bergmite',
            'avalugg',
            'bounsweet',
            'steenee',
            'tsareena',
            'komala',
            'turtonator',
            'dhelmise',
            'pheromosa',
            'gossifleur',
            'eldegoss',
            'rolycoly',
            'carkol',
            'coalossal',
            'mr-rime',
            'morpeko-full-belly',
            'regieleki',
            'quaxly',
            'bramblin',
            'brambleghast',
            'toedscool',
            'cyclizar',
            'tatsugiri',
            'great-tusk',
            'iron-treads',
            'terapagos',
            'blastoise-mega',
            'pikachu-sinnoh-cap',
            'sandshrew-alola',
            'sandslash-alola',
            'mr-mime-galar',
            'morpeko-hangry',
            'avalugg-hisui',
            'tatsugiri-droopy',
            'tatsugiri-stretchy',
            'terapagos-terastal',
            'terapagos-stellar',
        ];
        const tidymons = [
            'sentret',
            'furret',
            'minccino',
            'cinccino',
            'maushold',
            'maushold-family-of-three',
        ];
        const rockmons = [
            'sandshrew',
            'sandslash',
            'nidoqueen',
            'nidoking',
            'clefairy',
            'clefable',
            'jigglypuff',
            'wigglytuff',
            'diglett',
            'dugtrio',
            'primeape',
            'geodude',
            'graveler',
            'golem',
            'onix',
            'cubone',
            'marowak',
            'rhyhorn',
            'rhydon',
            'chansey',
            'pinsir',
            'omanyte',
            'omastar',
            'kabuto',
            'kabutops',
            'aerodactyl',
            'mew',
            'sudowoodo',
            'wooper',
            'quagsire',
            'pineco',
            'forretress',
            'dunsparce',
            'gligar',
            'steelix',
            'shuckle',
            'slugma',
            'magcargo',
            'swinub',
            'piloswine',
            'corsola',
            'skarmory',
            'phanpy',
            'donphan',
            'miltank',
            'blissey',
            'larvitar',
            'pupitar',
            'tyranitar',
            'celebi',
            'marshtomp',
            'swampert',
            'nosepass',
            'mawile',
            'aron',
            'lairon',
            'aggron',
            'numel',
            'camerupt',
            'torkoal',
            'trapinch',
            'vibrava',
            'flygon',
            'lunatone',
            'solrock',
            'barboach',
            'whiscash',
            'baltoy',
            'claydol',
            'lileep',
            'cradily',
            'anorith',
            'armaldo',
            'kecleon',
            'relicanth',
            'metang',
            'metagross',
            'regirock',
            'registeel',
            'groudon',
            'rayquaza',
            'jirachi',
            'deoxys-normal',
            'turtwig',
            'grotle',
            'torterra',
            'chimchar',
            'monferno',
            'infernape',
            'piplup',
            'prinplup',
            'empoleon',
            'bidoof',
            'bibarel',
            'cranidos',
            'rampardos',
            'shieldon',
            'bastiodon',
            'shellos',
            'gastrodon',
            'bronzor',
            'bronzong',
            'bonsly',
            'gible',
            'gabite',
            'garchomp',
            'hippopotas',
            'hippowdon',
            'rhyperior',
            'gliscor',
            'mamoswine',
            'probopass',
            'uxie',
            'mesprit',
            'azelf',
            'dialga',
            'heatran',
            'arceus',
            'roggenrola',
            'boldore',
            'gigalith',
            'drilbur',
            'excadrill',
            'palpitoad',
            'seismitoad',
            'sandile',
            'krokorok',
            'krookodile',
            'dwebble',
            'crustle',
            'tirtouga',
            'carracosta',
            'archen',
            'archeops',
            'ferroseed',
            'ferrothorn',
            'stunfisk',
            'druddigon',
            'golett',
            'golurk',
            'pawniard',
            'bisharp',
            'hydreigon',
            'cobalion',
            'terrakion',
            'zekrom',
            'landorus-incarnate',
            'binacle',
            'barbaracle',
            'tyrunt',
            'tyrantrum',
            'amaura',
            'aurorus',
            'carbink',
            'diancie',
            'rockruff',
            'lycanroc-midday',
            'mudbray',
            'mudsdale',
            'sandygast',
            'palossand',
            'minior-red-meteor',
            'kommo-o',
            'nihilego',
            'necrozma',
            'stakataka',
            'drednaw',
            'rolycoly',
            'carkol',
            'coalossal',
            'silicobra',
            'sandaconda',
            'perrserker',
            'cursola',
            'runerigus',
            'stonjourner',
            'cufant',
            'copperajah',
            'duraludon',
            'kleavor',
            'nacli',
            'naclstack',
            'garganacl',
            'klawf',
            'tinkatink',
            'tinkatuff',
            'tinkaton',
            'bombirdier',
            'orthworm',
            'glimmet',
            'glimmora',
            'annihilape',
            'clodsire',
            'dudunsparce',
            'kingambit',
            'great-tusk',
            'scream-tail',
            'sandy-shocks',
            'iron-treads',
            'iron-thorns',
            'ting-lu',
            'archaludon',
            'terapagos',
            'deoxys-attack',
            'deoxys-defense',
            'deoxys-speed',
            'wormadam-sandy',
            'wormadam-trash',
            'landorus-therian',
            'pinsir-mega',
            'aerodactyl-mega',
            'tyranitar-mega',
            'mawile-mega',
            'aggron-mega',
            'garchomp-mega',
            'swampert-mega',
            'steelix-mega',
            'diancie-mega',
            'metagross-mega',
            'groudon-primal',
            'camerupt-mega',
            'sandshrew-alola',
            'sandslash-alola',
            'diglett-alola',
            'dugtrio-alola',
            'geodude-alola',
            'graveler-alola',
            'golem-alola',
            'marowak-alola',
            'lycanroc-midnight',
            'minior-orange-meteor',
            'minior-yellow-meteor',
            'minior-green-meteor',
            'minior-blue-meteor',
            'minior-indigo-meteor',
            'minior-violet-meteor',
            'minior-red',
            'minior-orange',
            'minior-yellow',
            'minior-green',
            'minior-blue',
            'minior-indigo',
            'minior-violet',
            'kommo-o-totem',
            'marowak-totem',
            'rockruff-own-tempo',
            'lycanroc-dusk',
            'necrozma-dusk',
            'necrozma-dawn',
            'necrozma-ultra',
            'meowth-galar',
            'corsola-galar',
            'stunfisk-galar',
            'growlithe-hisui',
            'arcanine-hisui',
            'avalugg-hisui',
            'dialga-origin',
            'wooper-paldea',
            'dudunsparce-three-segment',
            'terapagos-terastal',
            'terapagos-stellar',
        ];
        const spikemons = [
            'sandshrew',
            'sandslash',
            'shellder',
            'cloyster',
            'omanyte',
            'omastar',
            'mew',
            'sudowoodo',
            'wooper',
            'quagsire',
            'pineco',
            'forretress',
            'gligar',
            'qwilfish',
            'heracross',
            'delibird',
            'skarmory',
            'roselia',
            'cacnea',
            'cacturne',
            'whiscash',
            'snorunt',
            'glalie',
            'groudon',
            'budew',
            'roserade',
            'vespiquen',
            'gastrodon',
            'bonsly',
            'garchomp',
            'gliscor',
            'froslass',
            'venipede',
            'whirlipede',
            'scolipede',
            'maractus',
            'dwebble',
            'crustle',
            'trubbish',
            'garbodor',
            'ferroseed',
            'ferrothorn',
            'shelmet',
            'accelgor',
            'chespin',
            'quilladin',
            'chesnaught',
            'froakie',
            'frogadier',
            'greninja',
            'bunnelby',
            'diggersby',
            'carbink',
            'klefki',
            'diancie',
            'wimpod',
            'golisopod',
            'magearna',
            'naganadel',
            'rolycoly',
            'carkol',
            'coalossal',
            'pincurchin',
            'overqwil',
            'meowscarada',
            'tarountula',
            'spidops',
            'bramblin',
            'brambleghast',
            'toedscool',
            'toedscruel',
            'orthworm',
            'glimmet',
            'glimmora',
            'clodsire',
            'sandy-shocks',
            'iron-thorns',
            'ting-lu',
            'ogerpon',
            'deoxys-defense',
            'sandslash-alola',
            'greninja-battle-bond',
            'greninja-ash',
            'magearna-original',
            'qwilfish-hisui',
            'wooper-paldea',
            'ogerpon-wellspring-mask',
            'ogerpon-hearthflame-mask',
            'ogerpon-cornerstone-mask',
            'samurott-hisui'
        ];
        const fogmons = [
            'charizard',
            'butterfree',
            'beedrill',
            'pidgey',
            'pidgeotto',
            'pidgeot',
            'spearow',
            'fearow',
            'zubat',
            'golbat',
            'venomoth',
            'farfetchd',
            'scyther',
            'aerodactyl',
            'articuno',
            'zapdos',
            'moltres',
            'dragonite',
            'mew',
            'hoothoot',
            'noctowl',
            'ledian',
            'crobat',
            'togetic',
            'xatu',
            'yanma',
            'murkrow',
            'gligar',
            'scizor',
            'delibird',
            'mantine',
            'skarmory',
            'lugia',
            'ho-oh',
            'torchic',
            'combusken',
            'blaziken',
            'beautifly',
            'dustox',
            'seedot',
            'nuzleaf',
            'shiftry',
            'taillow',
            'swellow',
            'wingull',
            'pelipper',
            'masquerain',
            'ninjask',
            'volbeat',
            'illumise',
            'vibrava',
            'flygon',
            'swablu',
            'altaria',
            'castform',
            'tropius',
            'chimecho',
            'salamence',
            'latias',
            'latios',
            'rayquaza',
            'piplup',
            'prinplup',
            'empoleon',
            'starly',
            'staravia',
            'staraptor',
            'mothim',
            'vespiquen',
            'drifloon',
            'drifblim',
            'honchkrow',
            'stunky',
            'skuntank',
            'chatot',
            'carnivine',
            'finneon',
            'lumineon',
            'togekiss',
            'yanmega',
            'gliscor',
            'rotom',
            'giratina-altered',
            'arceus',
            'snivy',
            'servine',
            'serperior',
            'pidove',
            'tranquill',
            'unfezant',
            'woobat',
            'swoobat',
            'timburr',
            'gurdurr',
            'conkeldurr',
            'cottonee',
            'whimsicott',
            'sigilyph',
            'archen',
            'archeops',
            'ducklett',
            'swanna',
            'emolga',
            'cryogonal',
            'rufflet',
            'braviary',
            'vullaby',
            'mandibuzz',
            'hydreigon',
            'volcarona',
            'tornadus-incarnate',
            'thundurus-incarnate',
            'reshiram',
            'zekrom',
            'landorus-incarnate',
            'fletchling',
            'fletchinder',
            'talonflame',
            'vivillon',
            'florges',
            'hawlucha',
            'klefki',
            'noibat',
            'noivern',
            'xerneas',
            'yveltal',
            'volcanion',
            'rowlet',
            'dartrix',
            'decidueye',
            'pikipek',
            'trumbeak',
            'toucannon',
            'oricorio-baile',
            'cutiefly',
            'ribombee',
            'fomantis',
            'lurantis',
            'comfey',
            'silvally',
            'drampa',
            'tapu-koko',
            'tapu-fini',
            'lunala',
            'kartana',
            'rookidee',
            'cramorant',
            'sirfetchd',
            'frosmoth',
            'giratina-origin',
            'rotom-heat',
            'rotom-wash',
            'rotom-frost',
            'rotom-fan',
            'rotom-mow',
            'castform-sunny',
            'castform-rainy',
            'castform-snowy',
            'tornadus-therian',
            'thundurus-therian',
            'landorus-therian',
            'charizard-mega-x',
            'charizard-mega-y',
            'aerodactyl-mega',
            'scizor-mega',
            'blaziken-mega',
            'latias-mega',
            'latios-mega',
            'altaria-mega',
            'pidgeot-mega',
            'rayquaza-mega',
            'salamence-mega',
            'beedrill-mega',
            'oricorio-pom-pom',
            'oricorio-pau',
            'oricorio-sensu',
            'lurantis-totem',
            'ribombee-totem',
            'farfetchd-galar',
            'weezing-galar',
            'cramorant-gulping',
            'cramorant-gorging',
            'lilligant-hisui',
            'braviary-hisui',
        ];
        const suckerpunchmons = ['rattata',
            'raticate',
            'ekans',
            'arbok',
            'nidoran-m',
            'nidorino',
            'nidoking',
            'diglett',
            'dugtrio',
            'bellsprout',
            'weepinbell',
            'victreebel',
            'geodude',
            'graveler',
            'golem',
            'gastly',
            'haunter',
            'gengar',
            'voltorb',
            'electrode',
            'hitmonlee',
            'kangaskhan',
            'mr-mime',
            'mew',
            'sentret',
            'furret',
            'spinarak',
            'ariados',
            'chinchou',
            'lanturn',
            'natu',
            'xatu',
            'sudowoodo',
            'umbreon',
            'murkrow',
            'misdreavus',
            'girafarig',
            'corsola',
            'houndour',
            'houndoom',
            'stantler',
            'hitmontop',
            'celebi',
            'poochyena',
            'mightyena',
            'seedot',
            'nuzleaf',
            'shiftry',
            'slakoth',
            'vigoroth',
            'slaking',
            'shedinja',
            'skitty',
            'delcatty',
            'sableye',
            'mawile',
            'spinda',
            'cacnea',
            'cacturne',
            'seviper',
            'kecleon',
            'shuppet',
            'banette',
            'duskull',
            'dusclops',
            'absol',
            'huntail',
            'latias',
            'wormadam-plant',
            'drifloon',
            'drifblim',
            'mismagius',
            'honchkrow',
            'glameow',
            'purugly',
            'stunky',
            'skuntank',
            'bonsly',
            'mime-jr',
            'spiritomb',
            'croagunk',
            'toxicroak',
            'dusknoir',
            'froslass',
            'rotom',
            'darkrai',
            'tepig',
            'pignite',
            'emboar',
            'purrloin',
            'liepard',
            'maractus',
            'zorua',
            'joltik',
            'galvantula',
            'stunfisk',
            'druddigon',
            'pawniard',
            'heatmor',
            'furfrou',
            'meowstic-male',
            'phantump',
            'trevenant',
            'yveltal',
            'rowlet',
            'dartrix',
            'decidueye',
            'rockruff',
            'lycanroc-midday',
            'golisopod',
            'komala',
            'marshadow',
            'scorbunny',
            'raboot',
            'cinderace',
            'sobble',
            'drizzile',
            'inteleon',
            'nickit',
            'thievul',
            'applin',
            'clobbopus',
            'sinistea',
            'polteageist',
            'impidimp',
            'morgrem',
            'grimmsnarl',
            'mr-rime',
            'pincurchin',
            'dreepy',
            'dragapult',
            'urshifu-single-strike',
            'sprigatito',
            'tarountula',
            'nymble',
            'lokix',
            'tadbulb',
            'bellibolt',
            'wiglett',
            'wugtrio',
            'bombirdier',
            'brute-bonnet',
            'chien-pao',
            'dipplin',
            'hydrapple',
            'wormadam-sandy',
            'wormadam-trash',
            'rotom-heat',
            'rotom-wash',
            'rotom-frost',
            'rotom-fan',
            'rotom-mow',
            'meowstic-female',
            'gengar-mega',
            'kangaskhan-mega',
            'mawile-mega',
            'banette-mega',
            'absol-mega',
            'sableye-mega',
            'rattata-alola',
            'raticate-alola',
            'raticate-totem-alola',
            'diglett-alola',
            'dugtrio-alola',
            'rockruff-own-tempo',
            'lycanroc-dusk',
            'mr-mime-galar',
            'moltres-galar',
            'stunfisk-galar',
            'samurott-hisui',
            'decidueye-hisui',
        ];
        const espeedmons = ['arcanine',
            'dratini',
            'dragonite',
            'raikou',
            'entei',
            'suicune',
            'zigzagoon',
            'rayquaza',
            'lucario',
            'togekiss',
            'arceus',
            'zygarde-50',
            'regieleki',
            'deoxys-speed',
            'lucario-mega',
            'rayquaza-mega',
            'zygarde-10-power-construct',
            'zygarde-50-power-construct',
            'zygarde-complete',
            'zygarde-10',
            'arcanine-hisui', ];
        const bouncemons = ['ivysaur',
            'venusaur',
            'charmander',
            'charmeleon',
            'charizard',
            'blastoise',
            'raticate',
            'nidoqueen',
            'nidoking',
            'vulpix',
            'ninetales',
            'persian',
            'growlithe',
            'arcanine',
            'golem',
            'onix',
            'rhyhorn',
            'rhydon',
            'kangaskhan',
            'magmar',
            'gyarados',
            'lapras',
            'eevee',
            'vaporeon',
            'jolteon',
            'flareon',
            'aerodactyl',
            'articuno',
            'zapdos',
            'moltres',
            'dragonite',
            'mew',
            'cyndaquil',
            'quilava',
            'typhlosion',
            'croconaw',
            'feraligatr',
            'flaaffy',
            'ampharos',
            'espeon',
            'umbreon',
            'steelix',
            'snubbull',
            'granbull',
            'teddiursa',
            'ursaring',
            'swinub',
            'piloswine',
            'skarmory',
            'houndour',
            'houndoom',
            'phanpy',
            'donphan',
            'stantler',
            'raikou',
            'entei',
            'suicune',
            'tyranitar',
            'lugia',
            'ho-oh',
            'sceptile',
            'blaziken',
            'mudkip',
            'marshtomp',
            'swampert',
            'poochyena',
            'mightyena',
            'linoone',
            'vigoroth',
            'slaking',
            'whismur',
            'loudred',
            'exploud',
            'aron',
            'lairon',
            'aggron',
            'electrike',
            'manectric',
            'sharpedo',
            'wailmer',
            'wailord',
            'numel',
            'camerupt',
            'altaria',
            'zangoose',
            'tropius',
            'sealeo',
            'walrein',
            'bagon',
            'shelgon',
            'salamence',
            'latias',
            'latios',
            'kyogre',
            'groudon',
            'rayquaza',
            'turtwig',
            'grotle',
            'torterra',
            'infernape',
            'empoleon',
            'shinx',
            'luxio',
            'luxray',
            'cranidos',
            'rampardos',
            'shieldon',
            'bastiodon',
            'buizel',
            'floatzel',
            'purugly',
            'stunky',
            'skuntank',
            'gible',
            'gabite',
            'garchomp',
            'riolu',
            'lucario',
            'hippopotas',
            'hippowdon',
            'drapion',
            'rhyperior',
            'magmortar',
            'leafeon',
            'glaceon',
            'mamoswine',
            'dialga',
            'palkia',
            'heatran',
            'giratina-altered',
            'arceus',
            'tepig',
            'pignite',
            'emboar',
            'lillipup',
            'herdier',
            'stoutland',
            'zebstrika',
            'sandile',
            'krokorok',
            'krookodile',
            'darumaka',
            'darmanitan-standard',
            'scraggy',
            'scrafty',
            'archen',
            'archeops',
            'zorua',
            'zoroark',
            'eelektross',
            'axew',
            'fraxure',
            'haxorus',
            'beartic',
            'druddigon',
            'deino',
            'zweilous',
            'hydreigon',
            'cobalion',
            'terrakion',
            'virizion',
            'reshiram',
            'zekrom',
            'kyurem',
            'keldeo-ordinary',
            'chespin',
            'quilladin',
            'chesnaught',
            'litleo',
            'pyroar',
            'skiddo',
            'gogoat',
            'pancham',
            'pangoro',
            'furfrou',
            'tyrunt',
            'tyrantrum',
            'amaura',
            'aurorus',
            'sylveon',
            'avalugg',
            'xerneas',
            'volcanion',
            'litten',
            'torracat',
            'incineroar',
            'gumshoos',
            'rockruff',
            'lycanroc-midday',
            'mudbray',
            'mudsdale',
            'stufful',
            'bewear',
            'type-null',
            'silvally',
            'turtonator',
            'drampa',
            'jangmo-o',
            'hakamo-o',
            'kommo-o',
            'tapu-koko',
            'tapu-bulu',
            'solgaleo',
            'lunala',
            'yamper',
            'boltund',
            'duraludon',
            'zamazenta',
            'urshifu-single-strike',
            'zarude',
            'glastrier',
            'wyrdeer',
            'ursaluna',
            'fuecoco',
            'crocalor',
            'skeledirge',
            'fidough',
            'dachsbun',
            'maschiff',
            'mabosstiff',
            'greavard',
            'houndstone',
            'farigiraf',
            'great-tusk',
            'scream-tail',
            'iron-jugulis',
            'roaring-moon',
            'koraidon',
            'walking-wake',
            'okidogi',
            'archaludon',
            'gouging-fire',
            'raging-bolt',
            'terapagos',
            'giratina-origin',
            'darmanitan-zen',
            'kyurem-black',
            'kyurem-white',
            'keldeo-resolute',
            'venusaur-mega',
            'charizard-mega-x',
            'charizard-mega-y',
            'blastoise-mega',
            'kangaskhan-mega',
            'gyarados-mega',
            'aerodactyl-mega',
            'houndoom-mega',
            'tyranitar-mega',
            'blaziken-mega',
            'aggron-mega',
            'manectric-mega',
            'garchomp-mega',
            'lucario-mega',
            'latias-mega',
            'latios-mega',
            'swampert-mega',
            'sceptile-mega',
            'altaria-mega',
            'sharpedo-mega',
            'steelix-mega',
            'kyogre-primal',
            'groudon-primal',
            'rayquaza-mega',
            'camerupt-mega',
            'salamence-mega',
            'raticate-alola',
            'raticate-totem-alola',
            'vulpix-alola',
            'ninetales-alola',
            'persian-alola',
            'golem-alola',
            'gumshoos-totem',
            'lycanroc-midnight',
            'kommo-o-totem',
            'rockruff-own-tempo',
            'lycanroc-dusk',
            'zamazenta-crowned',
            'zarude-dada',
            'growlithe-hisui',
            'arcanine-hisui',
            'typhlosion-hisui',
            'zorua-hisui',
            'zoroark-hisui',
            'koraidon-limited-build',
            'koraidon-sprinting-build',
            'koraidon-swimming-build',
            'koraidon-gliding-build',
            'ursaluna-bloodmoon',
            'terapagos-terastal',
            'terapagos-stellar',
            'butterfree',
            'pidgey',
            'pidgeotto',
            'pidgeot',
            'spearow',
            'fearow',
            'zubat',
            'golbat',
            'venomoth',
            'farfetchd',
            'doduo',
            'dodrio',
            'aerodactyl',
            'snorlax',
            'articuno',
            'zapdos',
            'moltres',
            'mew',
            'hoothoot',
            'noctowl',
            'yanma',
            'murkrow',
            'skarmory',
            'lugia',
            'ho-oh',
            'beautifly',
            'dustox',
            'shiftry',
            'taillow',
            'masquerain',
            'whismur',
            'makuhita',
            'hariyama',
            'spoink',
            'tropius',
            'starly',
            'staravia',
            'staraptor',
            'cranidos',
            'rampardos',
            'munchlax',
            'hippopotas',
            'skorupi',
            'yanmega',
            'sigilyph',
            'rufflet',
            'braviary',
            'vullaby',
            'mandibuzz',
            'volcarona',
            'noibat',
            'noivern',
            'tapu-bulu',
            'cufant',
            'bombirdier',
            'slither-wing',
            'iron-hands',
            'iron-moth',
            'ting-lu',
            'aerodactyl-mega',
            'pidgeot-mega',
            'braviary-hisui',
            'charmander',
            'charmeleon',
            'charizard',
            'blastoise',
            'arbok',
            'nidoqueen',
            'nidoking',
            'onix',
            'lickitung',
            'rhydon',
            'gyarados',
            'dratini',
            'dragonair',
            'dragonite',
            'mew',
            'meganium',
            'feraligatr',
            'ampharos',
            'slowking',
            'steelix',
            'tyranitar',
            'lugia',
            'treecko',
            'grovyle',
            'sceptile',
            'aggron',
            'vibrava',
            'flygon',
            'seviper',
            'milotic',
            'tropius',
            'bagon',
            'shelgon',
            'salamence',
            'groudon',
            'rayquaza',
            'rampardos',
            'gible',
            'gabite',
            'garchomp',
            'lickilicky',
            'rhyperior',
            'dialga',
            'palkia',
            'giratina-altered',
            'arceus',
            'serperior',
            'samurott',
            'krokorok',
            'krookodile',
            'scraggy',
            'scrafty',
            'archeops',
            'eelektross',
            'axew',
            'fraxure',
            'haxorus',
            'druddigon',
            'deino',
            'zweilous',
            'hydreigon',
            'reshiram',
            'zekrom',
            'kyurem',
            'skrelp',
            'dragalge',
            'helioptile',
            'heliolisk',
            'tyrunt',
            'tyrantrum',
            'amaura',
            'aurorus',
            'goodra',
            'noivern',
            'zygarde-50',
            'salazzle',
            'turtonator',
            'drampa',
            'jangmo-o',
            'hakamo-o',
            'kommo-o',
            'guzzlord',
            'naganadel',
            'chewtle',
            'appletun',
            'dracozolt',
            'duraludon',
            'dreepy',
            'drakloak',
            'dragapult',
            'eternatus',
            'cyclizar',
            'dudunsparce',
            'iron-jugulis',
            'iron-thorns',
            'frigibax',
            'arctibax',
            'baxcalibur',
            'roaring-moon',
            'koraidon',
            'miraidon',
            'dipplin',
            'archaludon',
            'hydrapple',
            'gouging-fire',
            'raging-bolt',
            'giratina-origin',
            'kyurem-black',
            'kyurem-white',
            'charizard-mega-x',
            'charizard-mega-y',
            'blastoise-mega',
            'gyarados-mega',
            'tyranitar-mega',
            'aggron-mega',
            'garchomp-mega',
            'steelix-mega',
            'groudon-primal',
            'rayquaza-mega',
            'salamence-mega',
            'exeggutor-alola',
            'zygarde-10-power-construct',
            'zygarde-50-power-construct',
            'zygarde-complete',
            'salazzle-totem',
            'kommo-o-totem',
            'zygarde-10',
            'eternatus-eternamax',
            'goodra-hisui',
            'dialga-origin',
            'palkia-origin',
            'dudunsparce-three-segment',
            'koraidon-limited-build',
            'koraidon-sprinting-build',
            'koraidon-swimming-build',
            'koraidon-gliding-build',
            'miraidon-low-power-mode',
            'miraidon-drive-mode',
            'miraidon-aquatic-mode',
            'miraidon-glide-mode',
            'gouging-fire'
        ];

        let test = "";
        if (smogonName) {
            test = smogonName;
        }

        for (let i = 0; i < rockmons.length; i++) {
            if (rockmons[i] === test) {
                setHasRocks("Stealth Rocks")
                break;
            }
        }
        for (let i = 0; i < bouncemons.length; i++) {
            if (bouncemons[i] === test) {
                setHasBounce("Bounce")
                break;
            }
        }
        for (let i = 0; i < spinmons.length; i++) {
            if (spinmons[i] === test) {
                setHasSpin("Rapid Spin")
                break;
            }
        }
        for (let i = 0; i < fogmons.length; i++) {
            if (fogmons[i] === test) {
                setHasFog("Defogger")
                break;
            }
        }
        for (let i = 0; i < wishmons.length; i++) {
            if (wishmons[i] === test) {
                setHasWish("Wish")
                break;
            }
        }
        for (let i = 0; i < curemons.length; i++) {
            if (curemons[i] === test) {
                setHasCureStatus("Cures Status")
                break;
            }
        }
        for (let i = 0; i < screenmons.length; i++) {
            if (screenmons[i] === test) {
                setHasScreens("Screens")
                break;
            }
        }
        for (let i = 0; i < webmons.length; i++) {
            if (webmons[i] === test) {
                setHasWebs("Webs")
                break;
            }
        }
        for (let i = 0; i < spikemons.length; i++) {
            if (spikemons[i] === test) {
                setHasSpikes("Spikes")
                break;
            }
        }
        for (let i = 0; i < suckerpunchmons.length; i++) {
            if (suckerpunchmons[i] === test) {
                setHasSucker("Sucker Punch")
                break;
            }
        }
        for (let i = 0; i < espeedmons.length; i++) {
            if (espeedmons[i] === test) {
                setHasExtremeSpeed("Extreme Speed")
                break;
            }
            for (let i = 0; i < tidymons.length; i++) {
                if (tidymons[i] === test) {
                    setHasTidyUp("Tidy Up")
                    break;
                }
            }
        }
    }, [hasRocks, name, smogonName])

    return (
        // unowned mon
        owned ? <div className="pokemonOwner"><span className="monIconHolders"></span>
                <div><h5 className="monName" onClick={() => {
                    window.open("https://www.smogon.com/dex/sv/pokemon/" + smogonName)
                }}>
                    {name} ({mon.pts})
                    <span
                        className="tooltiptext">view {smogonName} at smogon.com</span></h5></div>

                <img alt={mon.name} className="pokemonImage" src={src}/>
                <ul className="pokeTable">
                    <span> <a className="monAbility" href={"https://www.smogon.com/dex/sv/abilities/" + mon.ability1}><span
                        className="tooltiptext">view {mon.ability1} at smogon.com</span>{mon.ability1}</a></span>
                    <span><a className="monAbility" href={"https://www.smogon.com/dex/sv/abilities/" + mon.ability2}><span
                        className="tooltiptext">view {mon.ability2} at smogon.com</span>{mon.ability2}</a></span>
                    <span><a className="monAbility" href={"https://www.smogon.com/dex/sv/abilities/" + mon.ability3}><span
                        className="tooltiptext">view {mon.ability3} at smogon.com</span>{mon.ability3}</a></span>
                    <span><TypeShow2 type={type1}/><TypeShow2 type={type2}/></span>
                </ul>

                <div className="statHolderMonColumn">
                    <ul className="goDownStatsLeft">
                        <li className="statLeft">Atk: {atk}</li>
                        <ul className="statLeft">SpA: {spa}</ul>
                        <ul className="statLeft">Spe: {spe}</ul>
                    </ul>
                    <ul className="goDownStatsRight">
                        <ul className="statRight">Def: {def}</ul>
                        <ul className="statRight">SpD: {spdf}</ul>
                        <li className="statRight">HP: {hp}</li>
                    </ul>
                </div>

                <div>
                    <h5 className="showMonDoesRocks">{hasRocks}</h5>
                    <h5 className="showMonDoesWebs">{hasWebs}</h5>
                    <h5 className="showMonDoesSpins">{hasSpin}</h5>
                    <h5 className="showMonDoesFog">{hasFog}</h5>
                    <h5 className="showMonDoesWish">{hasWish}</h5>
                    <h5 className="showMonDoesCures">{hasCureStatus}</h5>
                    <h5 className="showMonDoesScreens">{hasScreens}</h5>
                    <h5 className="showMonDoesScreens">{hasSpikes}</h5>
                    <h5 className="showMonDoesScreens">{hasExtremeSpeed}</h5>
                    <h5 className="showMonDoesScreens">{hasTidyUp}</h5>
                    <h5 className="showMonDoesScreens">{hasSucker}</h5>
                </div>
            <br />
            <p className="monStatsHeader">{mon.ownerTeam}</p>
                {mon.gamesPlayed != "0" ? <ul className="monStats">
                    <li>Kills : {mon.kills} | Deaths : {mon.deaths}</li>
                    <li>Played : {mon.gamesPlayed} | Wins : {mon.gamesWon}</li>

                </ul> : <ul className="monStats">Unplayed</ul>}

            </div> :
            // owned mon
            <div className="pokemon"><span className="monIconHolders"></span>
                <div><h5 className="monName" onClick={() => {
                    window.open("https://www.smogon.com/dex/sv/pokemon/" + smogonName)
                }}>
                    {name} ({mon.pts})
                    <span
                        className="tooltiptext">view {smogonName} at smogon.com</span></h5></div>

                <img alt={mon.name} className="pokemonImage" src={src}/>
                <ul className="pokeTable">
                    <span> <a className="monAbility"
                              href={"https://www.smogon.com/dex/sv/abilities/" + mon.ability1}><span
                        className="tooltiptext">view {mon.ability1} at smogon.com</span>{mon.ability1}</a></span>
                    <span><a className="monAbility"
                             href={"https://www.smogon.com/dex/sv/abilities/" + mon.ability2}><span
                        className="tooltiptext">view {mon.ability2} at smogon.com</span>{mon.ability2}</a></span>
                    <span><a className="monAbility"
                             href={"https://www.smogon.com/dex/sv/abilities/" + mon.ability3}><span
                        className="tooltiptext">view {mon.ability3} at smogon.com</span>{mon.ability3}</a></span>
                    <span><TypeShow2 type={type1}/><TypeShow2 type={type2}/></span>
                </ul>

                <div className="statHolderMonColumn">
                    <ul className="goDownStatsLeft">
                        <li className="statLeft">Atk: {atk}</li>
                        <ul className="statLeft">SpA: {spa}</ul>
                        <ul className="statLeft">Spe: {spe}</ul>
                    </ul>
                    <ul className="goDownStatsRight">
                        <ul className="statRight">Def: {def}</ul>
                        <ul className="statRight">SpD: {spdf}</ul>
                        <li className="statRight">HP: {hp}</li>
                    </ul>
                </div>

                <div>
                    <h5 className="showMonDoesRocks">{hasRocks}</h5>
                    <h5 className="showMonDoesWebs">{hasWebs}</h5>
                    <h5 className="showMonDoesSpins">{hasSpin}</h5>
                    <h5 className="showMonDoesFog">{hasFog}</h5>
                    <h5 className="showMonDoesWish">{hasWish}</h5>
                    <h5 className="showMonDoesCures">{hasCureStatus}</h5>
                    <h5 className="showMonDoesScreens">{hasScreens}</h5>
                    <h5 className="showMonDoesScreens">{hasSpikes}</h5>
                    <h5 className="showMonDoesScreens">{hasExtremeSpeed}</h5>
                    <h5 className="showMonDoesScreens">{hasTidyUp}</h5>
                    <h5 className="showMonDoesScreens">{hasSucker}</h5>

                </div>

                <p className="monStatsHeader">{mon.ownerTeam}</p>
                {mon.gamesPlayed != "0" ? <ul className="monStats">
                        <li>Kills : {mon.kills} </li>
                      <li>  Deaths : {mon.deaths}</li>
                    <li>Played : {mon.gamesPlayed} </li>
                        <li>Wins : {mon.gamesWon}</li>

                </ul> : null}
            </div>

    )
}