
export default function Information() {
    return (
        <div className="content">
            <div className="marginParagraphAbout">

            <h1>ABOUT</h1>
            <p>Welcome to the PREMIER online monster battles draft league experience. </p>
            <p>All data on this website is the legal property of their respective owner, and I take no ownership of any information on this website.</p>
            <p>All media, rap lyrics, and any combination of 0s and 1s are posted here under fair use.</p>
            <p>Have fun and be yourself!</p>

        </div>
        </div>
    )
}